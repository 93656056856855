import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

const paths = {
  en: "/en/blog",
  it: "/it/blog",
  br: "/br/blog",
  fr: "/fr/blog",
  de: "/de/blog",
  es: "/blog",
}

export default props => (
  <>
    <Layout
      image="/android-chrome-512x512.png"
      lang={props.pageContext.lang}
      title="Blog"
      description="Blog"
      paths={paths}
      pageType="blog"
    >
      <div className="wrapper">
        <div className="container">
          <div className="row">
            {props.data.allContentfulBlogPost.edges.map(({ node }, index) => {
              return (
                <>
                  {index !== 0 && index % 3 === 0 && (
                    <div className="col-12 mt-3 d-none">
                      <div className="card">
                        <div className="card-body text-center">
                          <img
                            src="https://placehold.co/728x90"
                            alt="not found"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-4 col-lg-4 col-xl-4 mt-5">
                    <Link
                      to={`${getBlogPath(node.lang)}/${node.slug}/`}
                      title={`${getBlogPath(node.lang)}/${node.slug}/`}
                      style={{
                        textDecoration: "none",
                        color: "dimgrey",
                        fontSize: "0.9rem",
                      }}
                    >
                      <div className="card">
                        <Img
                          alt={node.heroImage.title}
                          title={node.heroImage.title}
                          fadeIn="false"
                          loading="eager"
                          style={{ maxWidth: 450, maxHeight: 253 }}
                          fluid={node.heroImage.fluid}
                        />
                        <div className="card-body">
                          <h2 className="card-title">{node.title}</h2>
                          <p className="card-text">{node.excerpt}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  </>
)

export const query = graphql`
  query($lang: String!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            title
            fluid(
              maxHeight: 600, maxWidth: 1200, quality: 60,
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
